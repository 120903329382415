import { template as template_0491287259c64bc4aafaa99471966a08 } from "@ember/template-compiler";
const FKLabel = template_0491287259c64bc4aafaa99471966a08(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
