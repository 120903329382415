import { template as template_1fbb927f7d5c41d9bd0ff851e0f50123 } from "@ember/template-compiler";
const WelcomeHeader = template_1fbb927f7d5c41d9bd0ff851e0f50123(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
