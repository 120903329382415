import { template as template_28cbf446b18c4954acdad82b37ac5182 } from "@ember/template-compiler";
const FKText = template_28cbf446b18c4954acdad82b37ac5182(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
